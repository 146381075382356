import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '@services/account.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const account = this.accountService.accountValue;
        if (account) {
            // compruebo si la ruta esta restringida por rol
            /* if (route.data.roles && !route.data.roles.includes(account.role)) {
                // rol no autorizado, redirigir a la página de inicio
                this.router.navigate(['/']);
                return false;
            } */

            // autorizado, devuélvalo verdadero
            return true;
        }

        // no esta logueado, redirigir a la página de inicio de sesión con la url de retorno 
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}