import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Account } from '@models/account';

const baseUrl = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;

    // private permisos:[];

    public get getPermisos() {
        if (localStorage.getItem('AcrePermisos'))
            return JSON.parse(atob(localStorage.getItem('AcrePermisos')));
    }

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.accountSubject = new BehaviorSubject<Account>(JSON.parse(localStorage.getItem('AcreUser')));
        this.account = this.accountSubject.asObservable();
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${baseUrl}/login`, { username, password })
            .pipe(map(resp => {
                if (resp.token) {
                    let account = {
                        id: resp.user.id,
                        name: resp.user.name,
                        username: resp.user.username,
                        email: resp.user.email,
                        perfilId: resp.user.perfil_id,
                        perfilNombre: resp.user.perfil_nombre,
                        empresaId: resp.user.empresa_id,
                        superadmin: resp.user.superadmin,
                        jwtToken: resp.token
                    };
                    localStorage.setItem('AcreUser', JSON.stringify(account));
                    localStorage.setItem('AcrePermisos', resp.permisos);
                    this.accountSubject.next(account);
                    /* this.startRefreshTokenTimer(); */
                }

                return resp.message;
            }));
    }

    logout() {
        this.http.get<any>(`${environment.apiUrl}/logout`).subscribe();
        /* this.stopRefreshTokenTimer(); */
        localStorage.removeItem('AcreUser');
        localStorage.removeItem('AcrePermisos');
        this.accountSubject.next(null);
        this.router.navigate(['/auth/login']);
    }

    /* refreshToken() {
        return this.http.post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: false })
            .pipe(map((account) => {
                this.accountSubject.next(account);
                this.startRefreshTokenTimer();
                return account;
            }));
    } */
    validateToken() {
        return this.http.post<any>(`${baseUrl}/validate-token`, {}, { withCredentials: false })
            .pipe(map((account) => {
                /* this.accountSubject.next(account);
                this.startRefreshTokenTimer(); */
                return account;
            }));
    }

    register(account: Account) {
        return this.http.post(`${baseUrl}/register`, account);
    }

    verifyEmail(token: string) {
        return this.http.post(`${baseUrl}/verify-email`, { token });
    }
    
    forgotPassword(email: string) {
        return this.http.post(`${baseUrl}/forgot-password`, { email });
    }
    
    validateResetToken(token: string) {
        return this.http.post(`${baseUrl}/validate-reset-token`, { token });
    }
    
    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
    }

    getAll() {
        return this.http.get<Account[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<Account>(`${baseUrl}/${id}`);
    }
    
    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.accountValue.id) {
                    // publish updated account to subscribers
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
                }
                return account;
            }));
    }
    
    delete(id: number) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
                // auto logout if the logged in account was deleted
                if (id === this.accountValue.id)
                    this.logout();
            }));
    }

    // helper methods

    /* private refreshTokenTimeout; */

    /* private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    } */

    /* private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    } */

    public esSuperAdmin() {
        if (this.accountValue !== null) {
            if (this.accountValue.superadmin == 1)
                return true;
            else
                return false;
        } else {
            return false;
        }        
    }

    public accesoModulos(modulo?: number): any[] {
        let datos = [];
        let accesos: any = this.getPermisos;
        
        if (modulo) {// retorno solo el modulo enviado
            accesos.forEach( (item, indice) => {
                let arrModulo = [];

                if (item.id == modulo) {
                    arrModulo['id'] = item.id;
                    arrModulo['nombre'] = item.nombre;
                    arrModulo['checked'] = (item.checked) ? true : false;

                    datos = arrModulo;
                }
            });
        } else {
            accesos.forEach( (item, indice) => {
                let arrModulo = [];

                arrModulo['id'] = item.id;
                arrModulo['nombre'] = item.nombre;
                arrModulo['checked'] = (item.checked) ? true : false;

                datos.push(arrModulo);
            });
        }

        return datos;
    }

    public accesoSecciones(seccion?: number): any[] {
        let datos = [];
        let accesos: any = this.getPermisos;

        if (accesos !== undefined) {
            if (seccion) {// retorno solo la seccion enviada
                accesos.forEach( (item, indice) => {
                    item.secciones.forEach( (sitem, sindice) => {
                        let arrSeccion = [];
    
                        if (sitem.id == seccion) {
                            arrSeccion['id'] = sitem.id;
                            arrSeccion['nombre'] = sitem.nombre;
                            arrSeccion['checked'] = (sitem.checked) ? true : false;
    
                            datos = arrSeccion;
                        }
                    });
                });
            } else {
                accesos.forEach( (item, indice) => {
                    item.secciones.forEach( (sitem, sindice) => {
                        let arrSeccion = [];
    
                        arrSeccion['id'] = sitem.id;
                        arrSeccion['nombre'] = sitem.nombre;
                        arrSeccion['checked'] = (sitem.checked) ? true : false;
    
                        datos.push(arrSeccion);
                    });
                });
            }
        }
        
        return datos;
    }

    public permisosSecciones(seccion: number, permiso?: number): any[] {
        let datos = [];
        let accesos: any = this.getPermisos;

        if (accesos !== undefined) {
            if (permiso) {// retorno solo el permiso enviado
                accesos.forEach( (item, indice) => {
                    item.secciones.forEach( (sitem, sindice) => {
                        if (sitem.id == seccion) {
                            sitem.permisos.forEach( (pitem, pindice) => {
                                let arrPermiso = [];
    
                                if (pitem.id == permiso) {
                                    arrPermiso['id'] = pitem.id;
                                    arrPermiso['nombre'] = pitem.nombre;
                                    arrPermiso['checked'] = (pitem.checked) ? true : false;
    
                                    datos = arrPermiso;
                                }
                            });
                        }
                    });
                });
            } else {
                accesos.forEach( (item, indice) => {
                    item.secciones.forEach( (sitem, sindice) => {
                        if (sitem.id == seccion) {
                            sitem.permisos.forEach( (pitem, pindice) => {
                                let arrPermiso = [];
    
                                arrPermiso['id'] = pitem.id;
                                arrPermiso['nombre'] = pitem.nombre;
                                arrPermiso['checked'] = (pitem.checked) ? true : false;
    
                                datos.push(arrPermiso);
                            });
                        }
                    });
                });
            }
        }        

        return datos;
    }
}