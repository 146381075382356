import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	modulo?: number;
	seccion?: number;
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{
            path: "/app/inicio",
            title: "Inicio",
            icon: "fa-dashboard",
            type: "link",
            bookmark: true,
            active: true
        },
        /* {
			title: 'Entregas', icon: 'book', type: 'sub', active: false, children: [
				{ path: '/app/entregas/crear', title: 'Crear', type: 'link' },
                { path: '/app/entregas/gestion', title: 'Gestión', type: 'link' }
			]
		},
        {
			title: 'Solicitud de Servicios', icon: 'archive', type: 'sub', active: false, children: [
				{ path: '/app/solicitudes/crear', title: 'Crear', type: 'link' },
                { path: '/app/solicitudes/gestion', title: 'Gestión', type: 'link' }
			]
		},
        {
			title: 'Indicadores', icon: 'bar-chart-2', type: 'sub', active: false, children: [
				{ path: '/app/solicitudes/crear', title: 'Crear', type: 'link' },
                { path: '/app/solicitudes/gestion', title: 'Gestión', type: 'link' }
			]
		},
        {
			title: 'Maestros', icon: 'database', type: 'sub', active: false, children: [
				{
					title: 'Personal', icon: 'file-text', type: 'sub', children: [
						{ path: '/app/maestros/cargos', title: 'Cargos', type: 'link' },
                        { path: '/app/maestros/personal', title: 'Personal', type: 'link' }
					]
				},
                { path: '/app/maestros/contratistas', title: 'Contratistas', type: 'link' },
                { path: '/app/maestros/clientes', title: 'Clientes', type: 'link' },
                {
					title: 'Propiedades', icon: 'file-text', type: 'sub', children: [
						{ path: '/app/maestros/areas', title: 'Áreas', type: 'link' },
                        { path: '/app/maestros/blocks', title: 'Blocks', type: 'link' },
                        { path: '/app/maestros/unidadesmodelos', title: 'Modelos', type: 'link' },
                        { path: '/app/maestros/unidadestipos', title: 'Tipos', type: 'link' },
                        { path: '/app/maestros/unidades', title: 'Propiedades', type: 'link' }
					]
				},
                {
					title: 'Conjuntos Habitacionales', icon: 'file-text', type: 'sub', children: [
						{ path: '/app/maestros/rubrostipos', title: 'Tipos', type: 'link' },
                        { path: '/app/maestros/rubros', title: 'Conjuntos Habitacionales', type: 'link' }
					]
				}
			]
		},
        {
			title: 'Mantenedores', icon: 'layers', type: 'sub', active: false, children: [
				
                { path: '/app/mantenedores/serviciostipos', title: 'Tipos de Servicios', type: 'link' },
                { path: '/app/mantenedores/serviciostareas', title: 'Tareas de Servicios', type: 'link' },
                { path: '/app/mantenedores/serviciosorigenes', title: 'Orígenes de Servicios', type: 'link' },
                { path: '/app/mantenedores/serviciosprioridades', title: 'Prioridades de Servicios', type: 'link' },
                
                { path: '/app/mantenedores/cierresmotivos', title: 'Motivos de Cierres', type: 'link' },
                { path: '/app/mantenedores/preguntasfrecuentes', title: 'Preguntas Frecuentes', type: 'link' },
                {
					title: 'Ubicación', icon: 'file-text', type: 'sub', children: [
						{ path: '/app/mantenedores/paises', title: 'Paises', type: 'link' },
                        { path: '/app/mantenedores/regiones', title: 'Regiones', type: 'link' },
                        { path: '/app/mantenedores/ciudades', title: 'Ciudades', type: 'link' }
					]
				},
                {
					title: 'Gestor de Problemas', icon: 'file-text', type: 'sub', children: [
						{ path: '/app/mantenedores/fallastipologias', title: 'Tipologías de Fallas', type: 'link' },
                        { path: '/app/mantenedores/fallasrelacionadas', title: 'Fallas Relacionadas', type: 'link' },
                        { path: '/app/mantenedores/soluciones', title: 'Soluciones', type: 'link' }
					]
				}
                
			]
		}, */
		{
			title: 'Acreditaciones', icon: 'fa-tasks', type: 'sub', active: false, children: [
				{ path: '/app/acreditaciones/gestionar', title: 'Gestionar', type: 'link' }
			]
		},
		/* {
			title: 'Cumplimientos Laborales', icon: 'fa-clipboard', type: 'sub', active: false, children: [
				{ path: '/app/cumplimientoslaborales/gestionar', title: 'Gestionar', type: 'link' },
				{ path: '/app/cumplimientoslaborales/periodos', title: 'Periodos', type: 'link' }
			]
		}, */
		/* {
			title: 'Empresas', icon: 'fa-building-o', type: 'sub', active: false, children: [
				{ path: '/app/empresas/crear', title: 'Crear', type: 'link' },
				{ path: '/app/empresas/listado', title: 'Listado', type: 'link' },
				{ path: '/app/empresas/centroscostos', title: 'Centros de Costos', type: 'link' }
			]
		},
		{
			title: 'Trabajadores', icon: 'fa-male', type: 'sub', active: false, children: [
				{ path: '/app/trabajadores/crear', title: 'Crear', type: 'link' },
				{ path: '/app/trabajadores/listado', title: 'Listado', type: 'link' },
				{ path: '/app/trabajadores/cargos', title: 'Cargos', type: 'link' }
			]
		}, */
		{
			title: "Empresas",
            icon: "fa-building-o",
            type: "sub",
            active: false,
            modulo: 2,
			children: [
				{ 
					path: '/app/empresas',
					title: 'Empresas',
					type: 'link',
					seccion: 7
				},
				{ 
					path: '/app/centros-costos',
					title: 'Centros de Costos',
					type: 'link',
					seccion: 8
				}
			]
		},
		{
			title: "Trabajadores",
            icon: "fa-male",
            type: "sub",
            active: false,
            modulo: 3,
			children: [
				{ 
					path: '/app/trabajadores',
					title: 'Trabajadores',
					type: 'link',
					seccion: 9
				},
				{ 
					path: '/app/cargos',
					title: 'Cargos',
					type: 'link',
					seccion: 10
				}
			]
		},
		{
			title: "Maestros",
            icon: "fa-th-list",
            type: "sub",
            active: false,
            modulo: 4,
			children: [
				{ 
					path: '/app/maestros/documentos',
					title: 'Documentos',
					type: 'link',
					seccion: 11
				},
				{ 
					path: '/app/maestros/personal',
					title: 'Personal',
					type: 'link',
					seccion: 12
				},
				{ 
					path: '/app/maestros/periodos',
					title: 'Periodos',
					type: 'link',
					seccion: 13
				}
			]
		},
		{
			title: "Mantenedores",
            icon: "fa-align-justify",
            type: "sub",
            active: false,
            modulo: 5,
			children: [
				{ 
					path: '/app/mantenedores/instituciones-previsionales',
					title: 'Inst. Previsionales',
					type: 'link',
					seccion: 14
				},
				{ 
					path: '/app/mantenedores/paises',
					title: 'Países',
					type: 'link',
					seccion: 15
				},
				{ 
					path: '/app/mantenedores/regiones',
					title: 'Regiones',
					type: 'link',
					seccion: 16
				},
				{ 
					path: '/app/mantenedores/ciudades',
					title: 'Ciudades',
					type: 'link',
					seccion: 17
				},
				{ 
					path: '/app/mantenedores/comunas',
					title: 'Comunas',
					type: 'link',
					seccion: 18
				}
			]
		},
		{
            title: "Centro de Usuarios",
            icon: "fa-users",
            type: "sub",
            active: false,
            modulo: 6,
            children: [
                { 
					path: '/app/centrousuarios/usuarios',
					title: 'Usuarios',
					type: 'link',
					seccion: 19
				},
				{ 
					path: '/app/centrousuarios/bitacora',
					title: 'Bitácora',
					type: 'link',
					seccion: 20
				}
            ]
        },
		{
            title: "Configuraciones",
            icon: "fa-cog",
            type: "sub",
            active: false,
            modulo: 7,
            children: [
				{ 
					path: '/app/configuraciones/parametros',
					title: 'Parámetros',
					type: 'link',
					seccion: 21
				},
				{ 
					path: '/app/configuraciones/perfiles',
					title: 'Perfiles',
					type: 'link',
					seccion: 22
				}
            ]
        }
	];	

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
